














































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState, mapActions } from 'vuex'
import { ApiResponse, BankDeposit, Payment, Sale } from '@/interfaces'
import { DatePicker, Table } from 'element-ui'
import { invoiceTypes, paymentTypes } from '@/formHelpers'

interface DropdownAction {
  action: string
  payment?: Payment
}

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      payments: [] as Payment[],
      paymentsToAssign: [] as number[],
      period: [] as string[],
      periodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      paymentTypeFilter: '',
      paymentTypes,
      invoiceTypes,
      invoiceTypeFilter: '',
      paymentTotalAmount: 0,
      bankDepositFilter: null as number | null,
      chosenBankDeposit: null as number | null,
      removeFromBankDeposit: false,
      bankDeposits: [],
      sales: [],
      saleFilter: null,
      showAssignDepositModal: false,
      busy: false,
    }
  },
  computed: {
    ...mapState(['user', 'invoicesFilters']),
  },
  mounted() {
    if (this.$route.query.depositId) {
      this.bankDepositFilter = parseInt(this.$route.query.depositId as string)
    }

    if (
      this.invoicesFilters &&
      (this.invoicesFilters.previousRoute?.includes('payments') ||
        this.invoicesFilters.previousRoute?.includes('invoice_'))
    ) {
      this.setAccountingFilters()
    } else {
      this.setInvoicesFilters({})
    }

    this.fetchPayments()
    this.fetchBankDeposits()
    this.getSales()
  },
  methods: {
    ...mapActions(['setInvoicesFilters']),
    updateFilter() {
      this.setInvoicesFilters({
        sale: this.saleFilter,
        invoiceType: this.invoiceTypeFilter,
        paymentType: this.paymentTypeFilter,
        invoicingPeriod: this.period,
        bankDepositNumber: this.bankDepositFilter,
        previousRoute: this.$route.name,
      })

      this.fetchPayments()
    },
    fetchPayments() {
      if (!this.busy) {
        const loading = this.$loading({
          target: '.payments-container',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/payments`, {
            params: {
              period: this.period,
              paymentType: this.paymentTypeFilter,
              sale: this.saleFilter,
              deposit: this.bankDepositFilter,
              invoiceType: this.invoiceTypeFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.payments = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    fetchBankDeposits() {
      this.bankDeposits = []

      this.$api
        .get('/bank-deposits')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.bankDeposits = apiResponse.data.bankDeposits
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    getSales() {
      this.$api
        .get(`/sales`, {
          params: {
            type: 'simple',
            pagination: false,
            sale: this.saleFilter,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.sales = apiResponse.data
        })
    },
    assignDeposit() {
      if (!this.busy) {
        this.busy = true

        const data = {
          ids: this.paymentsToAssign,
          bankDeposit: this.chosenBankDeposit,
          removeFromBankDeposit: this.removeFromBankDeposit ? 'NULL' : '',
          period: this.period,
          sale: this.saleFilter,
          paymentType: this.paymentTypeFilter,
          deposit: this.bankDepositFilter,
          invoiceType: this.invoiceTypeFilter,
        }

        const loading = this.$loading({
          target: '.payments-container',
          text: 'Chargement des données...',
        })
        this.$api
          .put(`/payments/mass-assign`, data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.payments = apiResponse.data
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Modifications réalisées avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
              ; (this.$refs.paymentsTable as InstanceType<typeof Table>).clearSelection()
            loading.close()
            this.closeAssignDepositModal()
          })
      }
    },
    handleSelectionChange(selectedPayments: Payment[]) {
      this.paymentsToAssign = []
      selectedPayments.forEach((payment) => {
        if (payment?.id) {
          this.paymentsToAssign?.push(payment.id)
        }
      })
      this.paymentTotalAmount = selectedPayments.reduce((acc, payment) => acc + parseFloat(payment.amount ?? ''), 0)
    },
    getPaymentSummary() {
      const paymentCount = this.paymentsToAssign?.length ?? 0
      return paymentCount + ' règlement(s) pour un total de ' + this.formatCurrency(this.paymentTotalAmount)
    },
    getDepositLabel(bankDeposit: BankDeposit) {
      let depositLabel = bankDeposit.structure?.name + ' - Remise n° ' + bankDeposit.depositNumber
      if (bankDeposit?.date) {
        depositLabel += ' - ' + this.formatDate(bankDeposit?.date?.toString())
      }
      depositLabel += ' - ' + bankDeposit.type
      return depositLabel
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR').format(date)
      } else {
        return '-'
      }
    },
    formatCurrency(amount: string | number) {
      if (typeof amount === 'string') {
        amount = parseFloat(amount) ?? 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount).toString()
    },
    getInvoiceNumber(payment: Payment) {
      return payment.invoice?.invoiceNumber ?? ''
    },
    getInvoiceContactName(payment: Payment) {
      return payment.invoice?.contactName ?? ''
    },
    getPaymentType(payment: Payment) {
      return this.paymentTypes.find((type) => type.value === payment.type)?.label ?? ''
    },
    editPaymentInvoice(payment: Payment) {
      this.$router.push({
        name: 'invoice_edit',
        params: {
          id: payment.invoice?.id?.toString() ?? '',
        },
      })
    },
    openAssignDepositModal() {
      this.showAssignDepositModal = true
    },
    closeAssignDepositModal() {
      this.showAssignDepositModal = false
      this.removeFromBankDeposit = false
      this.chosenBankDeposit = null
    },
    handleItemCommand(command: DropdownAction) {
      // Assign payment to bank deposit
      if (command.action == 'massAssignDeposit') {
        this.openAssignDepositModal()
      }
    },
    handleClose(done: CallableFunction) {
      this.closeAssignDepositModal()
      done()
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    setAccountingFilters() {
      this.saleFilter = this.invoicesFilters?.sale
      this.paymentTypeFilter = this.invoicesFilters?.paymentType
      this.invoiceTypeFilter = this.invoicesFilters?.invoiceType
      this.bankDepositFilter = this.invoicesFilters?.bankDepositNumber
      this.period = this.invoicesFilters?.invoicingPeriod
    },
    invoiceNumberSort(a: Payment, b: Payment) {
      let aInvoiceNumber = ''
      let bInvoiceNumber = ''
      if (!a.invoice?.invoiceNumber) {
        return -1
      } else {
        aInvoiceNumber = a.invoice?.invoiceNumber.replace('-', '')
      }
      if (!b.invoice?.invoiceNumber) {
        return 1
      } else {
        bInvoiceNumber = b.invoice?.invoiceNumber.replace('-', '')
      }
      return aInvoiceNumber.localeCompare(bInvoiceNumber, undefined, { numeric: true, sensitivity: 'base' })
    },
    sortContactName(itemA: Payment, itemB: Payment) {
      if (!itemA.invoice?.contactName) return -1
      if (!itemB.invoice?.contactName) return 1
      return itemA.invoice?.contactName.toUpperCase().localeCompare(itemB.invoice?.contactName.toUpperCase())
    },
    amountSort(itemA: Payment, itemB: Payment) {
      const amountA = parseFloat(String(itemA.amount))
      const amountB = parseFloat(String(itemB.amount))
      if (amountA == amountB) {
        return 0
      }

      return amountA < amountB ? -1 : 1
    },
    getStructureColor(payment: Payment) {
      return payment?.invoice?.structure?.color
    },
  },
})
